@font-face {
  font-family: shentox;
  src: url(./icons/Shentox_Bold.otf);
}

.link {
  text-decoration: none;
}
.text {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
}
.topbar {
  background-color: rgb(32, 32, 32);
  height: 5em;
  display: flex;
  justify-content: space-between;
}
.topbarbuttons {
  display: flex;
  justify-content: center;
}
.icon {
  display: flex;
  height: 4em;
  justify-content: space-between;
  margin: 0.5em;
}
.topbaricon {
  display: flex;
}
.topbaricon2 {
  width: 50px;
  height: 50px;
}
.mod {
  display: flex;
  max-width: 1000px;
  padding: 20px;
  background-color: rgb(32, 32, 32);
  margin: 0.4em;
  border-radius: 20px;
}
.modicon {
  max-height: 116px;
}
.topbartext {
  color: white;
  margin-top: 0.6em;
  margin-right: 1em;
  font-family: shentox;
}
.topbartext2 {
  color: rgb(4, 129, 0);
  margin-top: 0.5em;
  margin-right: 1em;
}
body {
  background-color: rgb(49,49,49);
}
.modlist {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-top: 4em;
  margin-left: 2em;
  margin-right: 2em;
  width: 1000px;
}
.mods {
  display: flex;
  justify-content: center;
}
.modtexts {
  margin-left: 1em;
  margin-right: 1em;
}
.modlist1 {
  display: flex;
  justify-content: center;
}
.gamepatchlist {
  display: none;
}
.modpage {
  display: flex;
  margin: 10em;
}
.modimg {
  height: 280px;
  margin-right: 2em;
}
.userprofile2 {
  display: flex;
}
.hide {
  display: none;
}
.moddownload {
  display: flex;
  justify-content: space-between;
}
.moddownloadimg {
  margin-right: 2em;
  max-height: 44px;
}
.userimg {
  max-height: 72px;
}
.userimg2 {
  max-height: 172px;
}